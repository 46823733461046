
import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import {
  Col,
  Row,
  Tab,
  Tabs,
  Popup,
  Icon,
  Popover,
  Button,
  NavBar,
  DropdownMenu,
  DropdownItem,
  Checkbox,
  CheckboxGroup,
} from "vant";
import Collection from "./Collection.vue";

@Component({
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Popover.name]: Popover,
    [Popup.name]: Popup,
    [Icon.name]: Icon,
    [Button.name]: Button,
    [NavBar.name]: NavBar,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    Collection,
  },
})
export default class myCollections extends Mixins(Mixin) {
  projectList: Array<member.CollectProjectRes> = [];
  tourList: member.CollectTourDTO[] = [];
  activeName = "a";
  label = "管理";
  checked = false;
  manageStatus = false;

  created(): void {
    window.document.title = "我的收藏";
    this.getCollection();
    this.getCollectTours();
  }
  onClickLeft(): void {
    if (this.AppSource == "harmonyOS") {
      this.jsBridge("back", "", () => {
        this.$router.go(-1);
      });
    } else {
      this.$router.go(-1);
    }
  }
  management(): void {
    // console.log(this.manageStatus);
    this.manageStatus = !this.manageStatus;
    if (this.manageStatus === true) {
      this.label = "取消";
    } else {
      this.label = "管理";
    }
  }

  // 获取收藏列表
  getCollection(): void {
    this.$api.memberApi.attentionAndCollection.getCollectProjectList(
      { pageNum: 1, pageSize: 10 },
      ({ data }) => {
        if (data.records) {
          this.projectList = data.records;
          // this.projectList = null as never;
        }
      }
    );
  }
  // 获取收藏巡演列表
  getCollectTours(): void {
    this.$api.memberApi.attentionAndCollection.getCollectTours((data) => {
      this.tourList = data.data;
      // this.tourList = null as never;
    });
  }
}
